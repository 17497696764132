<template>
  <div>
    <portal to="page-top-title">New User</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/users" redirect="users-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="2" :max="250" required/>
          </column-input>
          <column-input>
            <form-group-input name="email" type="email" :model="formData.email" :max="250" auto="off" required/>
          </column-input>
          <column-input>
            <form-group-select name="role_id" title="Role" :model="formData.role_id"
                               :options="roles" :disabled="!roles.length" required/>
          </column-input>
        </row>
        <row>
          <column-input>
            <form-group-input name="password" :model="formData.password" :min="12" :max="250" auto="new-password" required>
              <btn slot="help" type="button" color="warning" size="xs" @click.native.prevent="generatePswd" flat text>Generate</btn>
            </form-group-input>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import Btn from '@/views/components/simple/Btn'
import Form from '@/modules/utils/Form'
import Password from '@/modules/utils/Password'

export default {
  name: 'UsersNew',
  metaInfo () {
    return { title: 'Users | New' }
  },
  components: {
    Btn,
    BoxForm,
    FormGroupCheck,
    FormGroupInput,
    FormGroupSelect
  },
  data () {
    return {
      roles: [],
      formData: {
        name: Form.types.input(),
        email: Form.types.input(),
        password: Form.types.input(),
        role_id: Form.types.select(),
        active: Form.types.boolean(true)
      }
    }
  },
  created () {
    this.loadRoles()
  },
  methods: {
    loadRoles () {
      this.$http
        .get('/users/roles')
        .then((res) => {
          const { data: { data } } = res

          this.roles = data.map((role) => { return { id: role.id, name: role.name } })
        })
    },
    generatePswd () {
      this.formData.password.value = Password.generate(20)
    }
  }
}
</script>
